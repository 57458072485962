<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
          v-model="dialogDuplicateCourse"
          scrollable
          persistent
          max-width="1000px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sao chép khóa học</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDuplicateCourse = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">
                    Tên khóa học mới
                  </label>
<!--                  <v-text-field-->
<!--                      outlined-->
<!--                      placeholder="Enter text ..."-->
<!--                      v-model="titleDuplicateInput"-->
<!--                      required-->
<!--                  ></v-text-field>-->

                  <div>
                    <v-card>
                      <v-tabs
                          v-model="title_lang_tab"
                          background-color="blue-grey lighten-1"
                          dark
                      >
                        <v-tab  v-for="(itemLang, key) in titleDuplicateInput" :key="key">
                          {{ getLabelByLangCode(itemLang.lang) }}
                        </v-tab>
                      </v-tabs>
                    </v-card>

                    <template v-for="(item, key) in titleDuplicateInput">
                      <div :key="key">
                        <template v-if="title_lang_tab === key">
                          <v-text-field
                              filled
                              placeholder="Enter text ..."
                              v-model="item.text"
                              required
                          ></v-text-field>
                        </template>
                      </div>
                    </template>
                  </div>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="dialogDuplicateCourse = false"
              >Close
            </v-btn>
            <v-btn outlined color="blue darken-1" text @click="btnDuplicateCourse">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "CourseDuplicateForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      is_call_api: false,
      title_lang_tab: 0,
      titleDuplicateInput: [
        {lang: 'vi', text: ''},
        {lang: 'en', text: ''},
      ],

    };
  },
  computed: {
    dialogDuplicateCourse: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogDuplicateCourse(val) {
      if (val) {
        this.resetFormInput();
        this.setTitleCourse()
      }
    },
  },
  methods: {
    resetFormInput() {
      this.title_lang_tab = 0;
      this.titleDuplicateInput = [
        {lang: 'vi', text: ''},
        {lang: 'en', text: ''},
      ];
    },
    setTitleCourse() {
      this.titleDuplicateInput = [
        {lang: 'vi', text: (this.course.name.vi ?? '' ) + ' (Copy)'},
        {lang: 'en', text: (this.course.name.en ?? '' ) + ' (Copy)'},
      ];
    },
    async btnDuplicateCourse() {
      let vm = this;
      let data = {
        name: this.titleDuplicateInput,
        course_id: this.course.id,
      };
      this.is_call_api = true;
      await ApiService.post("prep-app/courses/duplicate", data)
      .then(function (res) {
        if (res.status == 200) {
          vm.$toasted.success('Duplicate thành công', {theme: "toasted-primary", position: "top-right", duration: 4000,});
          vm.dialogDuplicateCourse = false;
          vm.is_call_api = false;
          vm.$emit("resetList");
        }
      })
      .catch(function (error) {
        vm.is_call_api = false;
        if (error) {
          if ([422].includes(error.response.status) ) {
            vm.messError(error.response.data.message);
          } else {
            vm.messError('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
          }
        }
      });
    },
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    getLabelByLangCode(lang = '') {
      let label = "";
      switch (lang) {
        case "vi":
          label = "Tiếng Việt";
          break;
        case "en":
          label = "English";
          break;
        case "th":
          // label = "ไทย";
          label = "Thái Lan";
          break;
        default:
          label = "";
          break;
      }
      return label;
    },

  },
}
</script>

<style scoped>

</style>